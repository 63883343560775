import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { RouterProvider } from "react-router-dom";
import { userDataAtom } from "./atoms/auth.atom";
import { router } from "./routes";
import LoadingPage from "./components/LoadingPage";

const App = () => {
  const setUserData = useSetRecoilState(userDataAtom);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        setUserData(JSON.parse(userData));
      } catch (error) {
        console.error("Error parsing userData from localStorage:", error);
      }
    }
    setLoading(false);
  }, [setUserData]);

  if (loading) return <LoadingPage />;

  return <RouterProvider router={router} />;
};

export default App;
