import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../../../atoms/auth.atom";
import Button from "../../components/Button";
import Calendar from "./Calendar";
import { MdClose } from "react-icons/md";
import logo from "../../../assets/codey-logo.png";
import ContactModal from "../../components/ContactModal";

function Headers() {
  const navigate = useNavigate();
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);
  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);

  return (
    <header className="pt-8">
      <div className="container flex justify-between items-center gap-6 sm:gap-0 border-b border-gray-200">
        <span className="flex cursor-pointer" onClick={() => navigate("/")}>
          <img className="relative" src={logo} alt="logo-img" />
        </span>
        <div className="flex items-center gap-6 sm:flex-row py-3">
          <Button
            onClick={() => navigate("/solutions")}
            className="font-medium text-[#3F67AD]"
            style={{ fontSize: "24px", padding: "30px 60px" }}
          >
            Solutions
          </Button>
          {!!userData ? (
            <>
              <Button
                onClick={openContactModal}
                className="font-medium text-[#3F67AD]"
                style={{ fontSize: "24px", padding: "30px 60px" }}
              >
                Contact Us
              </Button>
              <Button
                onClick={toggleCalendar}
                className="text-[#3F67AD] font-medium text-lg"
              >
                Schedule Demo
              </Button>
              <Button
                onClick={() => {
                  setUserData(null);
                  localStorage.removeItem("userData");
                }}
                className="text-[#3F67AD] font-medium text-lg hidden sm:block"
              >
                Logout
              </Button>
              <Button
                onClick={() => navigate("/codeyai/projects")}
                className="btn"
              >
                Projects
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={openContactModal}
                className="font-medium text-[#3F67AD]"
                style={{ fontSize: "24px", padding: "30px 60px" }}
              >
                Contact Us
              </Button>
              <Button
                onClick={toggleCalendar}
                className="text-[#3F67AD] font-medium text-lg"
              >
                Schedule Demo
              </Button>
              <Button
                onClick={() => navigate("/codeyai/projects")}
                className="text-[#3F67AD] font-medium text-lg hidden sm:block"
              >
                Project Initializer
              </Button>
              <Button
                onClick={() => navigate("/login")}
                className="text-[#3F67AD] font-medium text-lg hidden sm:block"
              >
                Login
              </Button>
              <Button
                onClick={() => navigate("/codeyai/projects")}
                className="btn"
              >
                Try for free
              </Button>
            </>
          )}
        </div>
      </div>

      {isCalendarOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative z-60">
            <button
              onClick={toggleCalendar}
              className="absolute top-2 right-2 text-gray-600"
            >
              <MdClose size={24} />
            </button>
            <Calendar />
          </div>
        </div>
      )}

      {isContactModalOpen && (
        <ContactModal closeContactModal={closeContactModal} />
      )}
    </header>
  );
}

export default Headers;
