import TeamProjects from "./TeamProjects";

const MyTeamProjects = () => {
  return (
    <div className="sharedProjects w-full overflow-y-hidden">
      <div
        className="w-full overflow-y-auto custom-scrollbar pt-5 pb-5 flex flex-col gap-3"
        style={{ height: " calc(100vh - 85px)" }}
      >
        <TeamProjects />
      </div>
    </div>
  );
};

export default MyTeamProjects;
