import Button from "../../components/Button";
import emptyContent from "../../../assets/emptyContent.svg";
import del from "../../../assets/del.png";
import smallBtn from "../../../assets/smallbtn.svg";
import { deleteProject, getAllProjects } from "../fakeApi";

import projectBlock from "../../../assets/ProjectBlock.svg";

import PersonalProjects from "./PersonalProjects";
import { Link } from "react-router-dom";
import { useUserIdentity } from "../../../hooks/useUserIdentity";
import { Table } from "antd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Project Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Java Version",
    dataIndex: "javaVersion",
    key: "javaVersion",
  },
];

const RecentProjects = ({
  projects,
  getProjects,
  setIsAddModalOpen,
  projectsView,
}) => {
  const { canAccessDiagram } = useUserIdentity();
  const onDeleteProject = (id) => {
    deleteProject(id);
    getProjects();
  };

  if (projects.length && canAccessDiagram) {
    return (
      <>
        {projectsView === "grid" ? (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 max-w-full px-8 overflow-auto mb-24">
            {projects?.map?.((project) => {
              return (
                <div
                  className="relative"
                  key={project.tempProject?.id || project.id}
                >
                  <button
                    onClick={() =>
                      onDeleteProject(project.tempProject?.id || project.id)
                    }
                    className="absolute top-3 right-3 z-40"
                  >
                    <img className="w-[35px] h-[35px]" src={del} />
                  </button>
                  <Link
                    to={
                      "/codeyai/diagram/" +
                      (project?.tempProject?.id || project.id)
                    }
                  >
                    <PersonalProjects
                      image={projectBlock}
                      title={
                        project?.tempProject?.metaData?.name ||
                        project.metaData?.name
                      }
                      desc={
                        project?.tempProject?.metaData?.description ||
                        project.metaData?.description
                      }
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <Table
            dataSource={projects}
            columns={columns}
            className="mx-10 my-5"
            bordered
          />
        )}
      </>
    );
  }
  return (
    <div className="element-center flex-col gap-8 h-full py-9 sm:py-0">
      <div>
        <img src={emptyContent} alt="content-img" />
      </div>
      <p className="text-[20px] font-medium">No Projects created yet</p>
      <Button
        onClick={() => setIsAddModalOpen(true)}
        className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5`}
      >
        <span>
          <img src={smallBtn} alt="smallBtn" />
        </span>
        <span className="text-[14px] font-medium">New Project</span>
      </Button>
    </div>
  );
};

export default RecentProjects;
