import GitHub from "../../../assets/GitHub.svg";
import Linkedin from "../../../assets/Linkedin.svg";
import Twitter from "../../../assets/Twitter.svg";
import Youtube from "../../../assets/Youtube.svg";
import Facebook from "../../../assets/Facebook.svg";

function Footer() {
  return (
    <footer className="pt-[32px]">
      <div className="container flex justify-between items-center gap-[16px] md:gap-0 flex-col-reverse sm:flex-row border border-x-transparent mb-4">
        <div className="flex justify-between items-center gap-[50px] text-[#31313A] mb-[10px] sm:mb-0">
          <p>
            <a
              href="https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/Privacy%20Policy%20for%20CodeyAI.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>
          <p>
            <a
              href="https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/Privacy%20Policy%20for%20CodeyAI.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </p>
        </div>
        <div className="flex items-center gap-[16px] flex-row py-[12px] flex-wrap">
          <a href="https://github.com/orgs/codeyai">
            <span className="w-[38px] h-[40px] sm:w-[48px] sm:h-[50px] max-w-full">
              <img className="w-full h-fit" src={GitHub} alt="GitHubLogo" />
            </span>
          </a>
          <a href="https://www.linkedin.com/company/98050855">
            <span className="w-[38px] h-[40px] sm:w-[48px] sm:h-[50px] max-w-full">
              <img className="w-full h-fit" src={Linkedin} alt="LinkedinLogo" />
            </span>
          </a>
          {/* <a href="https://www.linkedin.com/company/98050855">
          <span className="w-[38px] h-[40px] sm:w-[48px] sm:h-[50px] max-w-full">
            <img className="w-full h-fit" src={Twitter} alt="TwitterLogo" />
          </span>
          </a> */}
          <a href="https://www.youtube.com/channel/UC3yXgIuUEMAEOG4EWBpChWA">
            <span className="w-[38px] h-[40px] sm:w-[48px] sm:h-[50px] max-w-full">
              <img className="w-full h-fit" src={Youtube} alt="YoutubeLogo" />
            </span>
          </a>

          <a href="https://www.facebook.com/o0codeyAi0o">
            <span className="w-[38px] h-[40px] sm:w-[48px] sm:h-[50px] max-w-full">
              <img className="w-full h-fit" src={Facebook} alt="FacebookLogo" />
            </span>
          </a>
        </div>
      </div>
      <p className="text-[#7B7F95] text-center sm:text-right px-12 pb-5">
        Copyright © 2024 CodeyAi
      </p>
    </footer>
  );
}

export default Footer;
