import { useState } from "react";
import Filters from "./Filters";
import PublicProjects from "./PublicProjects";

type tagsSelectedProps = {
  clicked: boolean;
  value: string[];
};
const ShareProjects = () => {
  const [tagsSearchClicked, setTagsSearchClicked] = useState<tagsSelectedProps>(
    {
      clicked: false,
      value: [],
    }
  );
  return (
    <div className="sharedProjects w-full overflow-y-hidden">
      <div
        className="w-full overflow-y-auto custom-scrollbar pt-5 pb-5 flex flex-col gap-3"
        style={{ height: " calc(100vh - 85px)" }}
      >
        <Filters setTagsSearchClicked={setTagsSearchClicked} />
        <PublicProjects
          tagsSearchClicked={tagsSearchClicked}
          setTagsSearchClicked={setTagsSearchClicked}
        />
      </div>
    </div>
  );
};

export default ShareProjects;
