import { Outlet } from "react-router-dom";
import SolutionsHeader from "../Solutions/Components/SolutionsHeader";

const SolutionsApp = () => {
  return (
    <>
      <SolutionsHeader />
      <Outlet />
    </>
  );
};

export default SolutionsApp;
