import heroImage from "../../assets/solutionImg.jpeg";

const Solutions = () => {
  return (
    <div>
      <img
        src={heroImage}
        alt="Hero Image"
        className="w-full h-[50vh] md:h-[80vh] lg:h-[100vh] "
      />

      <section className="py-16 bg-gradient-to-r from-cyan-500 via-purple-600 to-yellow-500">
        <div className="container mx-auto px-4 text-center text-white">
          <h2
            id="about-our-solutions"
            className="text-5xl md:text-6xl font-extrabold mb-6 leading-tight"
          >
            About Codeyai Solutions
          </h2>
          <p className="text-lg md:text-xl max-w-4xl mx-auto mb-6 leading-relaxed">
            Codeyai Solutions, a proud subsidiary of Codeyai, serves as a
            dedicated software house harnessing the cutting-edge Codeyai
            technology. With this advanced platform, we develop software
            solutions that are better, faster, and more cost-effective, setting
            a new standard for innovation and efficiency in the tech industry.
          </p>

          <p className="text-lg md:text-xl mt-4">
            For inquiries, reach out to us at{" "}
            <a
              href="mailto:Sales@codeyai.com"
              className="text-yellow-400 underline font-semibold hover:text-yellow-500 transition duration-300"
            >
              Sales@codeyai.com
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Solutions;
