import { configureStore } from '@reduxjs/toolkit';

import projectSlice from './project-slice';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
// 
const persistConfig = {
  key: 'project',
  storage,
  blacklist: ['currentPackages'],
};

const persistedReducer = persistReducer(persistConfig, projectSlice);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch