import { SearchOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { useState } from "react";

const Filters = ({ setTagsSearchClicked }) => {
  const [tagsSelected, setTagsSelected] = useState([]); // For tracking selected tags

  // Handle the search button click
  const handleSearchClick = () => {
    // if (tagsSelected.length > 0) {
    setTagsSearchClicked({ clicked: true, value: tagsSelected });
    // }
  };

  // Handle the "Tab" key for tag creation
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent default tabbing out behavior

      // Simulate "Enter" key behavior
      const enterEvent = new KeyboardEvent("keydown", {
        key: "Enter",
        keyCode: 13,
        code: "Enter",
        which: 13,
        bubbles: true,
      });
      event.target.dispatchEvent(enterEvent); // Dispatch the Enter key event
    }
  };

  return (
    <div className="flex pl-12 pr-12 justify-center items-center">
      <Select
        mode="tags"
        style={{ width: "50%" }}
        placeholder="Search Project Tags"
        onInputKeyDown={handleKeyDown} // Handle "Tab" for creating tags
        value={tagsSelected}
        onChange={(value) => setTagsSelected(value)} // Update selected tags
        open={false}
        allowClear
        tokenSeparators={[","]} // Optionally allow tag creation with commas
      />
      <Button
        type="primary"
        icon={<SearchOutlined />} // Add the search icon inside the button
        onClick={handleSearchClick} // Handle search click
        style={{ marginLeft: "8px" }}
      />
    </div>
  );
};

export default Filters;
