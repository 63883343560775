import { useNavigate } from "react-router-dom";
import Ellipse4 from "../../../assets/Ellipse4.svg";
// import Ellipse5 from "../../../assets/Ellipse5.svg";
// import Ellipse6 from "../../../assets/Ellipse6.svg";
// import rightArrow from "../../../assets/rightArrow.svg";

function Wizards() {
  const navigate = useNavigate();
  const handleMeetTeemClick = () => {};

  return (
    <section className="bg-gradient-to-r from-[#1A2841] to-[#4169B1] pb-[72px] relative">
      <div className="element-center flex-col pt-[50px] px-[30px] mb-[65px] sm:mb-[48px]">
        <h2 className="pb-[24px] px-[25px] font-medium text-[20px] md:text-[28px] text-center text-[#FFF]">
          Our Wizards
        </h2>
        <p className="text-[#F4F5F7] text-[16px] md:text-[20px] font-normal text-center font-Sofia">
          Meet the masterminds behind Intelligent Diagram Based Programming :
        </p>
      </div>
      <div>
        <div className="flex items-center  justify-around flex-col sm:flex-row gap-8 flex-wrap">
          <div className="element-center w-[343px] h-[343px] max-w-full sm:w-[352px] sm:h-[352px] rounded-l-[176px] rounded-br-[176px] rounded-tr-[32px] bg-[#F4F5F7] shrink-0 shadow-[0_2px_25px_0_#3F67AD]">
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate("/wizards/1");
              }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/Mohamed%20ali_bio.html"
              className="bg-white element-center flex-col w-[319px] h-[319px] max-w-full sm:w[328px] sm:h-[328px] rounded-l-[176px] rounded-br-[176px] rounded-tr-[32px] shrink-0 hover:scale-x-[101%]"
            >
              <span>
                <img src={Ellipse4} alt="Ellipse4" />
              </span>
              <h3 className="text-center text-[20px] uppercase text-[#22222A] py-[16px] font-Russo">
                Mohamed Ali
              </h3>
              <p className="from-[#3F67AD] via-[#7084B3] to-[#FF8F94] bg-gradient-to-b bg-clip-text text-transparent text-[18px] text-center">
                Founder & CEO
              </p>
            </a>
          </div>
          {/* <div className="element-center w-[343px] h-[343px] max-w-full sm:w-[352px] sm:h-[352px] rounded-[48px] bg-[#F4F5F7] shrink-0 shadow-[0_2px_25px_0_#3F67AD]">
            <div className="bg-white element-center flex-col w-[319px] h-[319px] max-w-full sm:w[328px] sm:h-[328px] rounded-[40px] shrink-0">
              <img src={Ellipse5} alt="Ellipse5" />
              <h3 className="text-center text-[20px] uppercase text-[#22222A] py-[16px] font-Russo">
                Mohammed ali
              </h3>
              <p className="from-[#3F67AD] via-[#7084B3] to-[#FF8F94] bg-gradient-to-b bg-clip-text text-transparent text-[18px] text-center">
                CTO
              </p>
            </div>
          </div> */}
          {/* <div className="element-center w-[343px] h-[343px] max-w-full sm:w-[352px] sm:h-[352px] rounded-[176px] bg-[#F4F5F7] shrink-0 shadow-[0_2px_25px_0_#3F67AD]">
            <div className="bg-white element-center flex-col w-[319px] h-[319px] max-w-full sm:w[328px] sm:h-[328px] rounded-[176px] shrink-0">
              <img src={Ellipse6} alt="Ellipse6" />
              <h3 className="text-center text-[20px] uppercase text-[#22222A] py-[16px] font-Russo">
                Omar Ashraf
              </h3>
              <p className="from-[#3F67AD] via-[#7084B3] to-[#FF8F94] bg-gradient-to-b bg-clip-text text-transparent text-[18px] text-center">
                Lead AI Engineer
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="meet-team" onClick={handleMeetTeemClick}>
        <p>Meet Codeyai Team</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
          />
        </svg>
      </div>
    </section>
  );
}

export default Wizards;
