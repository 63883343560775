import axios from "axios";

const requester = axios.create({
  baseURL: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io",
  headers: {
    "Content-Type": "application/json",
    // accept: "*/*",
    put: {
      "Content-Type": "application/json",
    },
  },
});

const requesterWithNoAuth = axios.create({
  baseURL: "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io",
  headers: {
    "Content-Type": "application/json",
    // accept: "*/*",
    put: {
      "Content-Type": "application/json",
    },
  },
});

const refreshAuthToken = async () => {
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const response = await axios.get(
      "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io/getTokenByRefreshToken",
      {
        headers: { Authorization: `Bearer ${userData.refreshToken}` },
      }
    );

    localStorage.setItem(
      "userData",
      JSON.stringify({ ...userData, token: response.data })
    );
    const newToken = response.data.token;

    return newToken;
  } catch (error) {
    localStorage.removeItem("userData");
    throw error;
  }
};

requester.interceptors.request.use((config) => {
  const { token = "" } = JSON.parse(localStorage.getItem("userData")) || {};
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

requester.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    if (error.response && error.response.status === 401) {
      try {
        const newToken = await refreshAuthToken();

        error.config.headers["Authorization"] = `Bearer ${newToken}`;

        return requester.request(error.config);
      } catch (refreshError) {
        window.location = "/login";
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export { requester, requesterWithNoAuth };
