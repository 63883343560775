import { useRecoilValue } from "recoil";
import { userDataAtom } from "../atoms/auth.atom";

const useUserIdentity = () => {
  const userData = useRecoilValue(userDataAtom);
  const canGenerateCode = userData?.access?.includes?.("GENERATE");
  const canCreateProject = userData?.access?.includes?.("PROJECT");
  const canAccessDiagram = userData?.access?.includes?.("DIAGRAM");

  return {
    userData,
    canAccessDiagram,
    canCreateProject,
    canGenerateCode,
  };
};

export { useUserIdentity };
