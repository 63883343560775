import { SinglePackage } from "../models/project";

export function updatePackage(
  obj,
  targetKey,
  targetValue,
  nodes,
  childPackages
) {
  if (obj?.hasOwnProperty(targetKey)) {
    const localObj = { ...obj };
    if (obj[targetKey] === targetValue) {
      // return;
      if (nodes) {
        const x = nodes.map((n) => {
          let no = {
            id: n.id,
            position: n.position,
            type: n.type,
            height: n.height,
            width: n.width,
            data: {
              store: JSON.parse(JSON.stringify(n.data.store)),
            },
          };

          return no;
        });
        localObj.components = x;
      } else if (childPackages) localObj.childrenPackages = childPackages;
      return localObj;
    }

    const pkgs = localObj.childrenPackages?.map((p) => {
      return updatePackage(p, targetKey, targetValue, nodes, childPackages);
    });
    localObj.childrenPackages = [...pkgs];
    return localObj;
  }
}

export const deepSearchForPackage = (pkg: SinglePackage, pkgName) => {
  if (pkg?.hasOwnProperty("packageName")) {
    if (pkg.packageName === pkgName) return pkg;
    for (let i = 0; i < pkg.childrenPackages.length; i++) {
      const search = deepSearchForPackage(pkg.childrenPackages[i], pkgName);
      if (search) return search;
    }
    return null;
  }
};
