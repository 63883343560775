import { MdClose, MdEmail, MdPhone } from "react-icons/md";

const ContactModal = ({ closeContactModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-lg relative z-60">
        <button
          onClick={closeContactModal}
          className="absolute top-2 right-2 text-gray-600"
        >
          <MdClose size={24} />
        </button>
        <h2 className="text-2xl font-semibold text-center mb-6 text-[#3F67AD]">
          Contact Us
        </h2>
        <div className="text-center space-y-6">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-800">
              Sales & Billing
            </h3>
            <div className="flex items-center justify-center space-x-4">
              <MdEmail className="text-[#3F67AD]" size={20} />
              <a
                href="mailto:sales@codeyai.com"
                className="text-[#3F67AD] underline"
              >
                sales@codeyai.com
              </a>
            </div>
            <div className="flex items-center justify-center space-x-4">
              <MdPhone className="text-[#3F67AD]" size={20} />
              <a href="tel:+19174009934" className="text-[#3F67AD] underline">
                917-400-9934
              </a>
            </div>
          </div>
          <div className="border-t border-gray-200 pt-4">
            <h3 className="text-lg font-medium text-gray-800">
              Technical Support
            </h3>
            <div className="flex items-center justify-center space-x-4">
              <MdEmail className="text-[#3F67AD]" size={20} />
              <a
                href="mailto:support@codeyai.com"
                className="text-[#3F67AD] underline"
              >
                support@codeyai.com
              </a>
            </div>
            <div className="flex items-center justify-center space-x-4">
              <MdPhone className="text-[#3F67AD]" size={20} />
              <a href="tel:+19174009934" className="text-[#3F67AD] underline">
                917-400-9663
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
