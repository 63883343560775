import { useEffect, useState } from "react";
import {
  CloseOutlined,
  ExclamationCircleFilled,
  UpOutlined,
  FolderOutlined,
  LikeOutlined,
  LikeFilled,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Spin,
  Tag,
} from "antd";
import { addOrUpdateProject } from "../../fakeApi";
import { useNavigate } from "react-router-dom";
import { requester } from "../../../../requester";
import { toast } from "react-toastify";

// const items = [
//   {
//     label: "Delete",
//     key: "0",
//   },
// ];
const TeamProjects = () => {
  const [sharedProjects, setSharedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size

  const navigate = useNavigate();

  const { confirm } = Modal;

  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [addMemberloading, setAddMemberloading] = useState(false);
  const [projIdToAddMember, setProjIdToAddMember] = useState("");
  const [form] = Form.useForm();
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [deleteMemberLoading, setDeleteMemberLoading] = useState(false);
  const [deleteMemberForm] = Form.useForm();
  const [projIdToModifyMember, setProjIdToModifyMember] = useState(null);

  function fetchMyTeamProjects(page = 0, size = 10) {
    setLoading(true);
    requester
      .get(`/teamProjs?page=${page}&size=${size}`)
      .then((response: any) => {
        console.log("response", response);
        setSharedProjects(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong while fetching shared projects"
        );
        setLoading(false);
      });
  }

  useEffect(() => {
    // If no tags are selected, fetch all projects (normal pagination)
    fetchMyTeamProjects(currentPage - 1, pageSize);
  }, [currentPage, pageSize]);

  // const handleSharedProjectClick = (projectData) => {
  //   let data: any;
  //   try {
  //     data = JSON.parse(projectData.data);
  //   } catch (error) {
  //     toast.error("Invalid project");
  //     return; // Exit early if JSON parsing fails
  //   }

  //   if (data.store) {
  //     addOrUpdateProject(data, data.tempProject.id);
  //     navigate(`/codeyai/diagram/${data.tempProject.id}`);
  //   } else if (data && !data.store) {
  //     addOrUpdateProject(data, data.id);
  //     navigate(`/codeyai/diagram/${data.id}`);
  //   }
  // };

  const handleSharedProjectClick = (projectData) => {
    console.log("projectData", projectData.data);

    try {
      const data = JSON.parse(projectData.data);
      if (data.store) {
        addOrUpdateProject(data, data.tempProject.id);
        navigate(`/codeyai/diagram/${data.tempProject.id}`);
      } else if (data && !data.store && data.metaData) {
        addOrUpdateProject(data, data.id);
        navigate(`/codeyai/diagram/${data.id}`);
      } else {
        toast.warning("Something wrong with this project");
      }
    } catch (error) {
      toast.warning("Something wrong with this project");
    }
  };

  const handleIncreaseVoteClick = (projectId) => {
    setLoading(true);
    requester
      .get(`/increaseRank?projID=${encodeURIComponent(projectId)}`)
      .then((response: any) => {
        console.log("response", response);

        fetchMyTeamProjects(currentPage - 1, pageSize);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Something went wrong while voting up"
        );
        setLoading(false);
      });
  };

  const handleDeleteProject = (id) => {
    confirm({
      title: "delete my public project",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to delete this project?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        return requester
          .get(`/delTeamProj?projID=${encodeURIComponent(id)}`)
          .then((response: any) => {
            console.log("response", response);
            fetchMyTeamProjects(currentPage - 1, pageSize);
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              err?.response?.data?.message ||
                "Something went wrong while voting up"
            );
          });
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDeleteMember = async () => {
    const formBody = deleteMemberForm.getFieldsValue();
    const deleteMemberData = {
      ...formBody,
      projID: projIdToModifyMember,
    };

    if (formBody && projIdToModifyMember) {
      setDeleteMemberLoading(true);
      await requester
        .post("/delTeamMember", JSON.stringify(deleteMemberData))
        .then(() => {
          toast.success("Member deleted successfully");
          setDeleteMemberLoading(false);
          fetchMyTeamProjects(currentPage - 1, pageSize);
          setOpenDeleteMemberModal(false);
          deleteMemberForm.resetFields();
        })
        .catch((err) => {
          setDeleteMemberLoading(false);
          toast.error("Something went wrong, please try again");
        });
    } else {
      toast.error("Please enter the email address!");
    }
  };

  const handleAddMember = async () => {
    // console.log("projIdToAddMember", projIdToAddMember);
    const formBody = form.getFieldsValue();
    // console.log("formBody", formBody);
    // return;
    const addMemberData = {
      ...formBody,
      projID: projIdToAddMember,
    };

    console.log("addMemberData", addMemberData);
    // return;

    if (formBody && projIdToAddMember) {
      setAddMemberloading(true);
      await requester
        .post("/allowMember", JSON.stringify(addMemberData))
        .then((response: any) => {
          toast.success(
            <div style={{ textAlign: "center" }}>Member added successfully</div>
          );
          setAddMemberloading(false);
          fetchMyTeamProjects(currentPage - 1, pageSize);

          console.log("response", response);
        })
        .catch((err) => {
          setAddMemberloading(false);
          toast.error(
            <div style={{ textAlign: "center" }}>
              Something went wrong, please try again
            </div>
          );
        });
      setOpenAddMemberModal(false);
      form.resetFields();
    } else {
      toast.error(
        <div style={{ textAlign: "center" }}>
          Something went wrong, please try again
        </div>
      );
    }
  };

  const estimatedTotal = sharedProjects?.length
    ? currentPage * pageSize + sharedProjects?.length
    : currentPage * pageSize;

  if (loading) {
    return (
      <div style={{ height: "60vh", display: "grid", placeItems: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="projects-wrapper">
          {sharedProjects?.length > 0 ? (
            sharedProjects?.map((project) => {
              const isVoted = project.voted;
              return (
                <div
                  className="h-fit flex gap-6 pl-12 pr-10 py-5 border-b-[1px] relative"
                  key={project.proj} // Use project.id as key
                >
                  <div className="absolute top-8 right-12 cursor-pointer hover:scale-105">
                    <Dropdown
                      placement="bottomRight"
                      menu={{
                        items: [
                          {
                            label: (
                              <span
                                onClick={() => {
                                  setProjIdToAddMember(
                                    // encodeURIComponent(project.projID)
                                    project.projID
                                  );
                                  setOpenAddMemberModal(true);
                                }}
                                className="text-blue-500"
                              >
                                Add Member
                              </span>
                            ),
                            key: "0",
                          },
                          {
                            label: (
                              <span
                                onClick={() => {
                                  setProjIdToModifyMember(project.projID);
                                  setOpenDeleteMemberModal(true);
                                }}
                                className="text-red-500"
                              >
                                Delete Member
                              </span>
                            ),
                            key: "1",
                          },

                          {
                            label: (
                              <span
                                onClick={() =>
                                  handleDeleteProject(project.projID)
                                }
                                className="text-red-500"
                              >
                                Delete project
                              </span>
                            ),
                            key: "2",
                          },
                        ],
                      }}
                      trigger={["click"]}
                    >
                      <span className="bg-gray-100 px-1 pt-[4px] rounded">
                        <EllipsisOutlined
                          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                        />
                      </span>
                    </Dropdown>
                  </div>
                  <div className="w-full flex flex-col gap-1">
                    <div className="flex items-center gap-3">
                      <FolderOutlined
                        style={{ fontSize: "20px", color: "red" }}
                      />
                      <p
                        className="text-blue-600 cursor-pointer hover:text-blue-400"
                        style={{ fontWeight: "normal", fontSize: 16 }}
                        onClick={() => handleSharedProjectClick(project)}
                      >
                        {project.title}
                      </p>
                    </div>
                    <p
                      className="text-sm first-letter:capitalize text-gray-600"
                      style={{ fontSize: "12px" }}
                    >
                      {project.description}
                    </p>
                    {project?.tags && (
                      <div className="flex flex-wrap gap-y-1 mt-2">
                        {project.tags.map((tag, idx) => (
                          <Tag key={"tag" + idx}>{tag}</Tag>
                        ))}
                      </div>
                    )}
                    <div className="flex items-center justify-between h-full gap-2 text-[0.9rem]">
                      {/* { <span
                      className="text-blue-600 pr-1"
                      style={{ fontSize: "13px", textTransform: "capitalize" }}
                    >
                      {project.email}
                    </span> } */}
                      {/* {
                        <div>
                          <span>ranks: </span>
                          <span
                            className="font-bold text-gray-500"
                            style={{ fontSize: "", textTransform: "capitalize" }}
                          >
                            {project.rank}
                          </span>
                        </div>
                      } */}
                      <div></div>

                      <div className="flex items-end gap-2 -mt-7">
                        <span
                          className="text-gray-500 pl-1"
                          style={{ fontSize: "13px" }}
                        >
                          published{" "}
                          {new Date(project.timeStamp).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            if (!isVoted) {
                              handleIncreaseVoteClick(project.projID);
                            }
                          }}
                          title={isVoted ? "Voted" : "Vote"}
                        >
                          {isVoted ? (
                            <LikeFilled className="text-blue-500" />
                          ) : (
                            <LikeOutlined
                              className="hover:text-blue-500"
                              style={{ fontSize: "1.3rem" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="grid place-items-center h-[70vh]">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
        {sharedProjects?.length > 8 && (
          <div className="pagination-btns mt-4">
            <Pagination
              total={estimatedTotal}
              pageSize={pageSize}
              current={currentPage}
              onChange={(page, size) => {
                setCurrentPage(page);
                setPageSize(size);
              }}
              showSizeChanger
              align="center"
            />
          </div>
        )}
      </div>

      <Modal
        title="Add Member"
        open={openAddMemberModal}
        onOk={handleAddMember}
        onCancel={() => {
          setOpenAddMemberModal(false);
          form.resetFields();
        }}
        okButtonProps={{ loading: addMemberloading }} // Show loading on OK button
        okText="Add"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter the email address!" },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]} // Apply email validation rules
          >
            <Input placeholder="Enter member's email address" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Delete Member"
        open={openDeleteMemberModal}
        onOk={handleDeleteMember}
        onCancel={() => {
          setOpenDeleteMemberModal(false);
          deleteMemberForm.resetFields();
        }}
        okButtonProps={{ loading: deleteMemberLoading }}
        okText="Delete"
      >
        <Form form={deleteMemberForm} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter the email address!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="Enter member's email address" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TeamProjects;
