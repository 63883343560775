import React from "react";
import { useField } from "formik";

export const FormField = ({ label, id, inputProps }) => {
  const [field, meta] = useField(id);
  return (
    <div className="flex flex-col items-start gap-3 w-full max-w-full">
      <label className="text-center text-[#22222A] italic font-normal text-base leading-[25px]">
        {label}
      </label>
      <input
        className="placeholder:text-[11px] sm:placeholder:text-[14px] flex items-center gap-[10px] px-4 py-5 border border-[#E3E4EA] rounded-xl w-full max-w-full outline-[#3284F4]"
        placeholder={label}
        {...inputProps}
        {...field}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-500">{meta.error}</div>
      ) : null}
    </div>
  );
};
