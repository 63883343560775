import Button from "../components/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormField } from "../../components/UI/formField";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Alert, Spin } from "antd";
import { resetPassword } from "../../api/auth";

const ResetPWD = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [error, setError] = useState(null);

  const onSubmit = async (values: {
    email: string;
    oldPwd: string;
    newPwd: string;
    // confirmPassword: string;
  }) => {
    setLoading(true);
    //
    try {
      await resetPassword(values);
      // navigate("/login"); // redirect to login or any other page
      setMessage("Password has been successfully reset.");
    } catch (err: any) {
      console.log("err", err);

      setError(err.response?.data || "Failed to reset password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="resetPage">
      <Spin size="large" spinning={loading}>
        <div className="container element-center flex-col gap-4 pt-10 pb-16 ">
          <div className="flex flex-col items-center gap-4 font">
            <h1 className="text-2xl font-medium text-[#22222A] italic leading-normal">
              Reset Password
            </h1>
            <p className="italic font-normal text-base leading-[25px] text-[#464758]">
              Enter your current password and the new password you want to set.
            </p>
          </div>
          <div className="border rounded-xl border-[#3F67AD] flex items-start w-[40%] flex-col p-8 gap-8 flex-[1_0_0] bg-white">
            {/* {error && <div className="text-red-500">{error}</div>} */}
            {error && (
              <Alert
                message={"Error occurred: " + error}
                type="error"
                closable
                onClose={() => setError(null)}
                banner
                className="w-full max-w-full"
              />
            )}
            {message && (
              <div className="text-green-500">
                {message}{" "}
                <Link
                  to="/login"
                  className="text-[#3F67AD] underline hover:underline"
                >
                  Login
                </Link>
              </div>
            )}
            <Formik
              initialValues={{
                email: "",
                oldPwd: "",
                newPwd: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required("This field is required"),
                oldPwd: Yup.string()
                  .required("This field is required")
                  .min(8, "Password should be more than 8 characters"),
                newPwd: Yup.string()
                  .required("This field is required")
                  .min(8, "Password should be more than 8 characters"),
                // newPWD: Yup.string()
                //   .oneOf([Yup.ref("newPassword")], "Passwords must match")
                //   .required("This field is required"),
              })}
              onSubmit={onSubmit}
            >
              <Form className="flex items-start flex-col w-full max-w-full gap-8">
                <FormField
                  id="email"
                  label="email"
                  inputProps={{ type: "email" }}
                />
                <FormField
                  id="oldPwd"
                  label="Old Password"
                  inputProps={{ type: "password" }}
                />
                <FormField
                  id="newPwd"
                  label="New Password"
                  inputProps={{ type: "password" }}
                />

                <div className="flex flex-col w-full max-w-full">
                  <Button
                    type="submit"
                    className="element-center gap-6 px-7 py-4 bg-[#3F67AD] text-white rounded-xl w-full max-w-full"
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ResetPWD;
