import { createSlice } from '@reduxjs/toolkit';
import { ProjectModel, SinglePackage } from '../models/project';
import { getProject } from '../pages/Projects/fakeApi';
import { deepSearchForPackage, updatePackage } from '../utils/handleProject';
import { Node } from 'reactflow';
import { toast } from 'react-toastify';

type IProject = {
  prevPkgNamebeforeEdit: string | null,
  PkgNameAfterEdit: string | null,
  currentProject: ProjectModel | null;
  tempProject: ProjectModel | null;
  currentPackageName: string;
  currentPackages: SinglePackage[];
  allPackages: any[],
  deletedPkg: SinglePackage,
  allDeletedPkgs:SinglePackage[],
  allComponents: any[],
  
};

const initialState: IProject = {
  prevPkgNamebeforeEdit: null,
  PkgNameAfterEdit: null,
  currentProject: null,
  tempProject: null,
  currentPackageName: '',
  currentPackages: [],
  allPackages: [],
  deletedPkg:null,
  allDeletedPkgs: [],
  allComponents: [],
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reset(state) {
      state.prevPkgNamebeforeEdit= null;
      state.PkgNameAfterEdit= null;
      state.deletedPkg= null;
      state.allDeletedPkgs= [];
      state.tempProject= null;
      state.currentPackages = [];
      state.currentPackageName= '';
      state.allPackages= [];
      state.currentPackages = [];
    },
    initProject(state, {payload}: {payload: string}) {
      const project = getProject(payload)
      state.currentProject = {...project};
      state.tempProject = {...project};
      state.currentPackageName = project?.basePackage?.packageName;
    },
    updateCurrentProject(state) {
      state.currentProject = JSON.parse(JSON.stringify(state.tempProject));
    },
    updataTempMetaData(state, {payload}) {
      state.prevPkgNamebeforeEdit= state.tempProject.basePackage?.packageName;
      if (state.tempProject) {
        state.tempProject = {
          ...state.tempProject,
          metaData: payload,
          basePackage: {
            ...state.tempProject.basePackage,
            packageName: payload.packageName
          }
        }
        state.currentProject = {
          ...state.currentProject,metaData: payload,basePackage: {
            ...state.currentProject.basePackage,
            packageName: payload.packageName
          }}
      }
      state.PkgNameAfterEdit= payload.packageName;
      state.currentPackageName = payload.packageName;
    },
    addTempProject(state,{payload}) {
      state.tempProject = payload;
    },
    updateTempProject(state, {payload: {nodes, pkgName, pkgs}}: {payload: {pkgName: string; nodes?: Node<any, string>[], pkgs?: SinglePackage[]}}) {
      const basePackage = updatePackage(
        state.tempProject?.basePackage,
        "packageName",
        pkgName,
        nodes,
        pkgs
      );
      state.tempProject = JSON.parse(JSON.stringify({ ...state.tempProject, basePackage }));
      if (pkgs?.length > 0) {
        
        state.currentPackages = pkgs;
      }
    },
    handleUpdateCurrentPackages(state, {payload}: {payload: SinglePackage[]}) {
      state.currentPackages = payload;
    },
    handleUpdateAllPackages(state, {payload}: {payload: any[]}) {
      state.allPackages = payload;
    },
    handleChangeCurrentPackage(state, {payload}: {payload:string}) {
      state.currentPackageName = payload;
     
      
      const pkg = deepSearchForPackage(state.tempProject?.basePackage, payload);
     
      
      state.currentPackages = [...(pkg?.childrenPackages.length > 0 ? pkg?.childrenPackages : [])];
    },
 
    handleChangePkgName(state, { payload: { oldPkgName, newPkgName } }) {
      if (!newPkgName.includes(state.tempProject.basePackage.packageName)) {
        toast.error("Package name must include the base package name");
        return;
     }
     
      state.prevPkgNamebeforeEdit = oldPkgName;
      state.PkgNameAfterEdit = newPkgName;
      
     
    
      function findAndRemovePackage(packages, name) {
        for (let i = 0; i < packages.length; i++) {
          if (packages[i].packageName === name) {
            // Remove the package and return it
            const [removedPackage] = packages.splice(i, 1);
            return removedPackage;
          }
          // Recursively search in childrenPackages
          if (packages[i].childrenPackages && packages[i].childrenPackages.length > 0) {
            const removedPackage = findAndRemovePackage(packages[i].childrenPackages, name);
            if (removedPackage) {
              return removedPackage;
            }
          }
        }
        return null; // Package not found
      }
      
    
    
      function insertPackage(basePkg, parentName, pkg) {
        if (!basePkg.childrenPackages) {
          basePkg.childrenPackages = [];
        }
        state.currentPackages?.map(pkg => {
          if ( pkg.packageName === oldPkgName) {
            pkg.packageName = newPkgName;
          }
          })
        if (basePkg.packageName === parentName ) {
          basePkg.childrenPackages.push(pkg);
          return true; 
        }
        if (!parentName.includes(state.tempProject.basePackage.packageName)) {
        
        
          return false;
        }
        for (let i = 0; i < basePkg.childrenPackages.length; i++) {
          if (basePkg.childrenPackages[i].packageName === parentName) {
            if (!basePkg.childrenPackages[i].childrenPackages) {
              basePkg.childrenPackages[i].childrenPackages = [];
            }
            basePkg.childrenPackages[i].childrenPackages.push(pkg);
            return true;
          }
          // Recursively search in childrenPackages
          if (basePkg.childrenPackages[i].childrenPackages && basePkg.childrenPackages[i].childrenPackages.length > 0) {
            if (insertPackage(basePkg.childrenPackages[i], parentName, pkg)) {
              return true;
            }
          }
        }
        
        
        return false; // Parent package not found
      }
    
      function updatePackageNameAndMove(basePackage, oldName, newName) {
        const oldParts = oldName.split('.');
        const newParts = newName.split('.');
        
        // If the base package itself is being renamed
        if (oldParts.length === 1 && newParts.length === 1) {
          basePackage.packageName = newParts[0];
          return;
        }
    
        const newParentName = newParts.slice(0, -1).join('.');
      
        
        const newPackageName = newName;
    
        // Remove the package from its old location
        const packageToMove = findAndRemovePackage(basePackage.childrenPackages, oldName);
        if (!packageToMove) {
          console.error('Old package not found.');
          return;
        }
    
        // Update the package name
        packageToMove.packageName = newPackageName;
        
        // Insert the package into its new location
        if (!insertPackage(basePackage, newParentName, packageToMove)) {
          console.error('New parent package not found.');
          toast.error("parent package not found");
          return;
        }
      }
    
      // Update the package structure
      updatePackageNameAndMove(state.tempProject.basePackage, oldPkgName, newPkgName);
    
      // Update the current package name if it matches the old name
      if (state.currentPackageName === oldPkgName) {
        state.currentPackageName = newPkgName;
      }
    }
    ,
    
    handleDeletePackage (state, { payload: { pkgName } }) {
    
      // Function to delete package by its name
      const deletePackage = (packages, nameToDelete) => {
        state.currentPackages?.forEach(pkg => {
          if ( pkg.packageName === nameToDelete) {
            state.currentPackages = state.currentPackages.filter(p => p.packageName !== nameToDelete);
          }
          })
          for (let i = 0; i < packages.length; i++) {
              if (packages[i].packageName === nameToDelete) {
                  const deletedPkg = packages.splice(i, 1); 
                  // packages.splice(i, 1);// Remove the package from the array
                  state.deletedPkg = deletedPkg.length > 0 ? deletedPkg[0] : null;
                  return true;
              }
              // Recursively search in childrenPackages
              if (packages[i].childrenPackages && packages[i].childrenPackages.length > 0) {
                  if (deletePackage(packages[i].childrenPackages, nameToDelete)) {
                      // If the package is found and deleted in childrenPackages, no need to continue searching
                      return true;
                  }
              }
          }
          // Package not found
          return false;
      };
      
      // Call deletePackage function
      deletePackage(state.tempProject.basePackage.childrenPackages, pkgName);
  
      // Optionally, update state if needed
      // For example, if you want to update currentPackageName if it's equal to the deleted pkgName
      if (state.currentPackageName === pkgName) {
        state.currentPackageName = "com.example.demo";
        const pkg = deepSearchForPackage(state.tempProject?.basePackage, "com.example.demo");
        state.currentPackages = [...(pkg?.childrenPackages ?? [])];
      }
  
      // Return updated state or perform any side effects
      return state;
  }
  },
});

export const { reset,addTempProject,handleUpdateAllPackages,updataTempMetaData,handleDeletePackage, updateCurrentProject,handleChangePkgName, updateTempProject, initProject, handleUpdateCurrentPackages, handleChangeCurrentPackage } = projectSlice.actions;
export default projectSlice.reducer;