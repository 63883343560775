import React from "react";

const Calendar: React.FC = () => {
  return (
    <iframe
      src="https://calendar.zoho.com/eventreqForm/zz080112305c23ae13d0da2935869376b999b71379e335a049e5575307ff5a1540f353843cfb35285db450a19d9cffe3e44bc88a33?theme=0&l=en&tz=America%2FNew_York"
      frameBorder="0"
      scrolling="no"
      marginWidth={0}
      marginHeight={0}
      height="350px"
      width="432px"
      style={{ display: "block", margin: "0 auto", border: "none" }}
      title="Zoho Calendar"
    />
  );
};

export default Calendar;
