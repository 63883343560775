import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { MdClose, MdEmail, MdPhone } from "react-icons/md";
import logo from "../../../assets/codey-logo.png";
import Calendar from "../../LandingPage/components/Calendar";
import ContactModal from "../../components/ContactModal";

function SolutionsHeader() {
  const navigate = useNavigate();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);
  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);

  return (
    <header className="py-4 shadow-2xl sticky top-0 bg-white">
      <div className="container flex justify-between items-center gap-6 sm:gap-0">
        <span className="flex cursor-pointer" onClick={() => navigate("/")}>
          <img className="relative" src={logo} alt="logo-img" />
        </span>
        <div className="flex items-center gap-6 sm:flex-row py-3">
          <Button
            onClick={() => navigate("/")}
            className="font-medium text-[#3F67AD] text-lg"
          >
            Codeyai
          </Button>

          <Button
            onClick={openContactModal}
            className="font-medium text-[#3F67AD] text-lg"
          >
            Contact Us
          </Button>
          <a
            href="#about-our-solutions"
            className="font-medium text-[#3F67AD] text-lg"
          >
            About Us
          </a>
        </div>
      </div>

      {isCalendarOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative z-60">
            <button
              onClick={toggleCalendar}
              className="absolute top-2 right-2 text-gray-600"
            >
              <MdClose size={24} />
            </button>
            <Calendar />
          </div>
        </div>
      )}

      {isContactModalOpen && (
        <ContactModal closeContactModal={closeContactModal} />
      )}
    </header>
  );
}

export default SolutionsHeader;
