import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormField } from "../../components/UI/formField";
import Button from "../components/Button";
import { useState } from "react";
import { Link } from "react-router-dom";
import { recoverPassword } from "../../api/auth"; // replace with your actual API function
import { Alert, Spin } from "antd";

const RecoverPassword = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const onSubmit = async (values: { email: string }) => {
    setLoading(true);
    setError(null);
    try {
      await recoverPassword(values);
      setMessage("A password recovery link has been sent to your email.");
    } catch (err: any) {
      console.log(err);

      setError(err.response?.data || "Failed to send recovery email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="recoverPage">
      <Spin size="large" spinning={loading}>
        <div className="container element-center flex-col gap-4 pt-10 pb-16">
          <div className="flex flex-col items-center gap-4 font">
            <h1 className="text-2xl font-medium text-[#22222A] italic leading-normal">
              Recover Password
            </h1>
            <p className="italic font-normal text-base leading-[25px] text-[#464758]">
              Enter your email to receive a new password.
            </p>
          </div>
          <div className="border rounded-xl border-[#3F67AD] w-[33rem] flex items-start flex-col p-8 gap-8 flex-[1_0_0] bg-white">
            {message && (
              <div className="text-green-500">
                {message}{" "}
                <Link
                  to="/login"
                  className="text-[#3F67AD] underline hover:underline"
                >
                  Login
                </Link>
              </div>
            )}
            {/* {error && <div className="text-red-500">{error}</div>} */}
            {error && (
              <Alert
                message={"Error occurred: " + error}
                type="error"
                closable
                onClose={() => setError(null)}
                banner
                className="w-full max-w-full"
              />
            )}
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email")
                  .required("This field is required"),
              })}
              onSubmit={onSubmit}
            >
              <Form className="flex items-start flex-col w-full max-w-full gap-8">
                <FormField
                  id="email"
                  label="Email"
                  inputProps={{ type: "email" }}
                />
                <div className="flex flex-col w-full max-w-full">
                  <Button
                    type="submit"
                    className="element-center gap-6 px-7 py-4 bg-[#3F67AD] text-white rounded-xl w-full max-w-full"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send Recovery Email"}
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default RecoverPassword;
