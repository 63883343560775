import { Select, Modal, Form, Button, Tooltip } from "antd";
import React, { useState, useCallback } from "react";
import { SpringBootIntializer } from "./ProjectsInitializeForms/SpringBootIntializer";
import { options } from "./ProjectsInitializeForms/projectTypeOptions";
import { CoreJavaIntializer } from "./ProjectsInitializeForms/CoreJavaIntializer";
import { addOrUpdateProject } from "../fakeApi";
import uniqId from "uniqid";
import { useNavigate } from "react-router-dom";
import { ProjectModel } from "../../DigramCreator/models";
import { requester, requesterWithNoAuth } from "../../../requester";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { useUserIdentity } from "../../../hooks/useUserIdentity";
import { LoadingOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updataTempMetaData } from "../../../redux/project-slice";
import logo from "../../../assets/codey-logo.png";
import { JavaVertxIntializer } from "./ProjectsInitializeForms/JavaVertxIntializer";
import { SpringWebfluxIntializer } from "./ProjectsInitializeForms/SpringWebfluxIntializer";

const { confirm } = Modal;
export const AddProjectModal = ({
  projectData = null,
  isOpen,
  setIsOpen,
  mode = "add",
}) => {
  const navigate = useNavigate();
  const { Item } = Form;
  const [form] = Form.useForm();
  const { canCreateProject } = useUserIdentity();
  const [loading, setLoading] = useState(false);
  const tempProject = useSelector((state: any) => state.tempProject);
  const project_Type = Form.useWatch("project_Type", form);
  const dispatch = useDispatch();
  const [recentProjectsMetaData, setRecentProjectsMetaData] = useState(
    JSON.parse(localStorage.getItem("recentProjectMetaData")) || []
  );

  const recentSpringBootMetaData =
    recentProjectsMetaData?.filter(
      (proj) => proj.metaData.project_Type === "spring-boot-project"
    ) || [];
  const recentSpringWebfluxMetaData =
    recentProjectsMetaData?.filter(
      (proj) => proj.metaData.project_Type === "spring-webflux-project"
    ) || [];

  const recentMavenMetaData =
    recentProjectsMetaData?.filter(
      (proj) => proj.metaData.project_Type === "java-maven-project"
    ) || [];

  const recentVertxMetaData =
    recentProjectsMetaData?.filter(
      (proj) => proj.metaData.project_Type === "java-vertx-project"
    ) || [];

  const isEditMode = mode === "edit";
  const handleSubmit = useCallback(
    (data: any) => {
      if (isEditMode) {
        dispatch(updataTempMetaData(data));
        setIsOpen(false);
        return;
      }

      if (data.project_Type === "spring-boot-project") {
        data.digram = getSpringInitDigrame(data.name);
      } else if (data.project_Type === "spring-webflux-project") {
        data.digram = getSpringWebfluxInitDigrame(data.name);
      } else if (data.project_Type === "java-maven-project") {
        data.digram = getMavenInitDiagram(data.name);
      } else if (data.project_Type === "java-vertx-project") {
        data.digram = getVertixInitDiagram(data.name);
      }

      const id = uniqId("project");
      data.id = id;

      const localProject: ProjectModel = {
        id,
        metaData: {
          packageName: data.packageName,
          artifactId: data.artifactId,
          // bootVersion:data.bootVersion,
          description: data.description,
          groupId: data.groupId,
          javaVersion: data.javaVersion,
          language: data.language,
          name: data.name,
          packaging: data.packaging,
          project_Type: data.project_Type,
          type: data.type,
        },
        basePackage: {
          edges: [],
          components: [],
          digram: data.digram,
          packageName: data.packageName,
          childrenPackages: [],
          parentPackageName: "",
        },
      };

      if (
        data.project_Type === "spring-boot-project" ||
        data.project_Type === "spring-webflux-project"
      ) {
        localProject.metaData.bootVersion = data.bootVersion;
        localProject.metaData.dependencies = data.dependencies;
      }

      if (data?.download) {
        console.log("download empty project from form", {
          ...localProject,
          downloadOnly: true,
        });
        console.log("formData", data);

        // return;
        setLoading(true);
        requesterWithNoAuth
          .post(
            "/codeyaiRequest",
            JSON.stringify({
              ...localProject,
              downloadOnly: true,
            }),
            { responseType: "arraybuffer" }
          )
          .then((response: any) => {
            const blob = new Blob([response.data], { type: "octet/stream" });
            const fileName = "QCPReport1.zip";
            saveAs(blob, fileName);
          })
          .catch((err) => {
            toast.error(
              <div style={{ textAlign: "center" }}>
                Something went wrong, please try again
              </div>
            );
          })
          .finally(() => setLoading(false));
        return;
      }
      addOrUpdateProject(localProject, id);

      // handle recent projects metadata

      const recentProjectMetaData =
        JSON.parse(localStorage.getItem("recentProjectMetaData")) || [];

      if (
        data.project_Type === "spring-boot-project" &&
        recentSpringBootMetaData?.length >= 5
      ) {
        confirm({
          title:
            "You have reached the maximum number of saved Spring Boot projects metadata.",
          content:
            "Click OK to continue without saving your current metadata or Cancel to delete one and save this.",
          icon: <ExclamationCircleFilled />,
          onOk() {
            navigate("/codeyai/diagram/" + id);
          },
          onCancel() {},
        });
      } else if (
        data.project_Type === "spring-webflux-project" &&
        recentSpringWebfluxMetaData?.length >= 5
      ) {
        console.log("recentSpringWebfluxMetaData", recentSpringWebfluxMetaData);

        confirm({
          title:
            "You have reached the maximum number of saved Webflux projects metadata.",
          content:
            "Click OK to continue without saving your current metadata or Cancel to delete one and save this.",
          icon: <ExclamationCircleFilled />,
          onOk() {
            navigate("/codeyai/diagram/" + id);
          },
          onCancel() {},
        });
      } else if (
        data.project_Type === "java-maven-project" &&
        recentMavenMetaData?.length >= 5
      ) {
        confirm({
          title:
            "You have reached the maximum number of saved Maven projects metadata.",
          content:
            "Click OK to continue without saving your current metadata or Cancel to delete one and save this.",
          icon: <ExclamationCircleFilled />,
          onOk() {
            navigate("/codeyai/diagram/" + id);
          },
          onCancel() {},
        });
      } else if (
        data.project_Type === "java-vertx-project" &&
        recentVertxMetaData?.length >= 5
      ) {
        confirm({
          title:
            "You have reached the maximum number of saved vertx projects metadata.",
          content:
            "Click OK to continue without saving your current metadata or Cancel to delete one and save this.",
          icon: <ExclamationCircleFilled />,
          onOk() {
            navigate("/codeyai/diagram/" + id);
          },
          onCancel() {},
        });
      } else {
        console.log("recentProjectMetaData", recentProjectMetaData);
        console.log("data.project_Type", data.project_Type);
        console.log("recentSpringWebfluxMetaData", recentSpringWebfluxMetaData);

        const updatedProjectMetaData = [
          ...recentProjectMetaData,
          { id: localProject.id, metaData: localProject.metaData },
        ];
        localStorage.setItem(
          "recentProjectMetaData",
          JSON.stringify(updatedProjectMetaData)
        );
        navigate("/codeyai/diagram/" + id);
      }
    },
    [isEditMode, projectData, recentProjectsMetaData]
  );
  //

  console.log(
    "tempProject?.metaData?.project_Type",
    tempProject?.metaData?.project_Type
  );
  console.log("project_Type", project_Type);

  return (
    <Modal
      open={isOpen}
      footer={null}
      className="add-project-modal"
      onCancel={() => setIsOpen(false)}
      closable={false}
      width="100%"
      style={{ top: 10, height: "98vh" }}
    >
      <div className="add-modal">
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          style={{ overflow: "hidden" }}
          initialValues={
            projectData?.metaData || {
              type: "maven-project",
              language: "java",
              groupId: "com.example",
              artifactId: "demo",
              name: "demo",
              description: "Project Description",
              packageName: "com.example.demo",
              packaging: "jar",
              javaVersion: "22",
              download: false,
            }
          }
        >
          <div className="flex items-center border-b-[1px] gap-4 p-4 sticky top-0 z-10 bg-white mb-8">
            <div className="font-bold">
              <img src={logo} alt="" />
            </div>
            <Item name="download" className="ms-auto !mb-0">
              <Button
                type="primary"
                // htmlType="submit"
                className="min-w-[95px] flex items-center justify-center"
                onClick={() => {
                  form.setFieldValue("download", true);
                  form.submit();
                }}
              >
                {loading ? (
                  <LoadingOutlined style={{ fontSize: 18 }} spin />
                ) : (
                  "Download"
                )}
              </Button>
            </Item>
            <Tooltip
              placement="bottom"
              overlayInnerStyle={{ textAlign: "center" }}
              title={
                !canCreateProject &&
                "You must be logged in to build project Diagrams."
              }
            >
              <Button
                type="primary"
                className="[&>_button]:!text-white [&>_button:disabled]:opacity-70"
                // htmlType="submit"
                disabled={!canCreateProject}
                onClick={() => {
                  form.setFieldValue("download", false);
                  form.submit();
                }}
              >
                {isEditMode ? "Save" : "Create Project"}
              </Button>
            </Tooltip>
            <Button type="default" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
          </div>
          <Item
            label="type"
            name="project_Type"
            className="capitalize"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              placeholder="select project type"
              style={{ width: 200 }}
              options={options}
              disabled={isEditMode}
            />
          </Item>

          {project_Type === "java-maven-project" ? (
            <CoreJavaIntializer
              form={form}
              recentMavenMetaData={recentMavenMetaData}
              setRecentProjectsMetaData={setRecentProjectsMetaData}
            />
          ) : project_Type === "java-vertx-project" ? (
            <JavaVertxIntializer
              form={form}
              recentVertxMetaData={recentVertxMetaData}
              setRecentProjectsMetaData={setRecentProjectsMetaData}
            />
          ) : project_Type === "spring-boot-project" ? (
            <SpringBootIntializer
              form={form}
              recentSpringBootMetaData={recentSpringBootMetaData}
              setRecentProjectsMetaData={setRecentProjectsMetaData}
            />
          ) : project_Type === "spring-webflux-project" ? (
            <SpringWebfluxIntializer
              form={form}
              recentSpringWebfluxMetaData={recentSpringWebfluxMetaData}
              setRecentProjectsMetaData={setRecentProjectsMetaData}
            />
          ) : null}
        </Form>
      </div>
    </Modal>
  );
};

const getSpringInitDigrame = (porject_name) => {
  return `{"Name":{"components":[{"componentType":"class","unDeletable":"true","componentScope":"public static","innerClasses":[],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"main","returnType":"void","funcScope":"public","description":"SpringApplication.run(Application.class, args);\\n\\n    }\\n\\n}","parameters":[{"paramName":"args","paramType":"String[]"}],"functionsCall":[]}],"componentName":"Application"}]},\"nodes\":[{\"customData\":{"componentType":"class","unDeletable":"true","componentScope":"public","innerClasses":[],"annotations":["@SpringBootApplication"],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"main","returnType":"void","funcScope":"public static","description":"SpringApplication.run(Application.class, args);","parameters":[{"paramName":"args","paramType":"String[]"}],"functionsCall":[]}],"componentName":"Application"},\"height\":74,\"id\":\"dndnode_lkbol7wr\",\"position\":{\"x\":84.53120793874203,\"y\":-90.99858220030593},\"type\":\"ClassDigram\",\"width\":200}],\"edges\":[]}`;
};
const getSpringWebfluxInitDigrame = (porject_name) => {
  return `{"Name":{"components":[{"componentType":"class","unDeletable":"true","componentScope":"public static","innerClasses":[],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"main","returnType":"void","funcScope":"public","description":"SpringApplication.run(Application.class, args);\\n\\n    }\\n\\n}","parameters":[{"paramName":"args","paramType":"String[]"}],"functionsCall":[]}],"componentName":"Application"}]},\"nodes\":[{\"customData\":{"componentType":"class","unDeletable":"true","componentScope":"public","innerClasses":[],"annotations":["@SpringBootApplication"],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"main","returnType":"void","funcScope":"public static","description":"SpringApplication.run(Application.class, args);","parameters":[{"paramName":"args","paramType":"String[]"}],"functionsCall":[]}],"componentName":"Application"},\"height\":74,\"id\":\"dndnode_lkbol7wr\",\"position\":{\"x\":84.53120793874203,\"y\":-90.99858220030593},\"type\":\"ClassDigram\",\"width\":200}],\"edges\":[]}`;
};

const getMavenInitDiagram = (porject_name) => {
  return `{"Name":{"components":[{"componentType":"class","unDeletable":"true","componentScope":"public static","innerClasses":[],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"main","returnType":"void","funcScope":"public","description":"","parameters":[{"paramName":"args","paramType":"String[]"}],"functionsCall":[]}],"componentName":"App"}]},\"nodes\":[{\"customData\":{"componentType":"class","unDeletable":"true","componentScope":"public","innerClasses":[],"annotations":[],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"main","returnType":"void","funcScope":"public static","description":"","parameters":[{"paramName":"args","paramType":"String[]"}],"functionsCall":[]}],"componentName":"App"},\"height\":74,\"id\":\"dndnode_lkbol7wr\",\"position\":{\"x\":84.53120793874203,\"y\":-90.99858220030593},\"type\":\"ClassDigram\",\"width\":200}],\"edges\":[]}`;
};
const getVertixInitDiagram = (porject_name) => {
  return `{"Name":{"components":[{"componentType":"class","unDeletable":"true","componentScope":"public static","innerClasses":[],"classVariables":[],"functions":[{"id":"funclkbe2gap","funcName":"start","annotations": ["@Override"],"returnType":"void","funcScope":"public","description":"","parameters":[],"functionsCall":[]}],"componentName":"MainVerticle"}]},\"nodes\":[{\"customData\":{"componentType":"class","unDeletable":"true","componentScope":"public","innerClasses":[],"annotations":[],"classVariables":[],"functions":[{"id":"funclkbe2gap","annotations": ["@Override"],"funcName":"start","returnType":"void","funcScope":"public","description":"// get a config_element from the config file\\n\\n//deploy verticle \\\"ConsumerVerticle\\\"\\n\\n//deploy verticle \\\"ProducerVerticle\\\"","parameters":[],"functionsCall":[]}],"componentName":"MainVerticle","extends":"AbstractVerticle"},\"height\":74,\"id\":\"dndnode_lkbol7wr\",\"position\":{\"x\":84.53120793874203,\"y\":-90.99858220030593},\"type\":\"ClassDigram\",\"width\":200}],\"edges\":[]}`;
};
